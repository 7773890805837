import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import { Helmet } from "react-helmet"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { cx } from "@emotion/css"

import Img from "gatsby-image"
import Layout from "@components/layout"
import Button from "@components/button"
import { Wrap, Products, Item } from "@components/products"

import opengraphImage from "../images/opengraph.jpg"

const filtersSet = [
  {label: "All", match: ""},
  {label: "Dunk", match: "dunk"},
  {label: "Jordan", match: "jordan"},
  {label: "Yeezy", match: "yeezy"},
]

const Filters = styled.div`
  grid-column: 1 / -1;
  margin-bottom: 1rem;
  display: grid;
  grid-auto-flow: column;
  justify-content: end;
  position: sticky;
  top: var(--wrap-padding-y);
  z-index: 1;
  margin-right: -1px;
`

function Filter({ children, ...props }) {
  return (
    <Button
      {...props}
      styles={`
        background-color: var(--ground-color);
        background-image: linear-gradient(0deg, var(--ground-color), var(--ground-color));

        &:hover {
          background-image: linear-gradient(0deg, hsla(0, 0%, var(--border-color-l), 0.33), hsla(0, 0%, var(--border-color-l), 0.33)),
                            linear-gradient(0deg, var(--ground-color), var(--ground-color));
        }

        & + & {
          border-left: 0;
        }

        &.selected {
          color: var(--text-high);
          background-color: var(--border-color);
          background-image: none;
          z-index: 2;
        }
      `}
    >
      {children}
    </Button>
  )
}

function New() {
  return (
    <figcaption css={css`
      font-family: var(--monospace);
      font-size: 0.75rem;
      letter-spacing: 0.0625em;
      text-transform: uppercase;
      background-color: var(--border-color);
      padding: 0.5rem 1rem;
      position: absolute;
      top: 0;
      left: 0;
    `}>New</figcaption>
  )
}

export default function IndexPage({ data: { allMdx, allFile, mainPlaceholder } }){
  return null

  const { edges: products } = allMdx
  const { nodes: images } = allFile
  const [filter, setFilter] = useState("")

  return (
    <Layout>
      <Helmet>
        <title>DUNK.SUPPLY</title>
        <meta property="og:title" content="DUNK.SUPPLY" />
        <meta property="og:image" content={opengraphImage} />
        <meta property="og:type" content="website" />
      </Helmet>
      <Wrap>
        <Products>
          <Filters>
            {filtersSet.map((element, index) => (
              <Filter
                onClick={() => setFilter(element.match)}
                className={filter === element.match ? "selected" : null}
                key={index}
              >
                {element.label}
              </Filter>
            ))}
          </Filters>
          {products
            .filter(({ node: product }) =>
              product.frontmatter.brand.toLowerCase() === filter ||
              product.frontmatter.model.toLowerCase().includes(filter)
            )
            .map(({ node: product }) => {
            const foundImage = images.find(image => image.relativePath.includes(product.fields.relativeSlug))
            const image = foundImage ? foundImage.childImageSharp.fluid : mainPlaceholder.childImageSharp.fluid

            return (
              <Item
                className={cx({
                  "unavailable": product.frontmatter.status === "unavailable",
                })}
                rel="bookmark"
                key={product.id}
              >
                <ItemLink
                  status={product.frontmatter.status}
                  to={product.fields.slug}
                >
                  <figure style={{ position: "relative" }}>
                    <Img
                      fluid={image}
                      durationFadeIn={333}
                      alt=""
                    />
                    {product.frontmatter.new === true && (
                      <New />
                    )}
                  </figure>
                  <h3>
                    {product.frontmatter.collab ? `${product.frontmatter.collab} x ` : null}
                    {product.frontmatter.brand} {product.frontmatter.model}
                  </h3>
                  <span>
                    {product.frontmatter.size}
                  </span>
                  <data value={product.frontmatter.price}>
                    {product.frontmatter.status === "unavailable" ? "———" : `$${product.frontmatter.price}`}
                  </data>
                </ItemLink>
              </Item>
            )
          })}
        </Products>
      </Wrap>
    </Layout>
  )
}

const ItemLink = ({ status = null, to, ...props }) => {
  if (status === "unavailable") {
    return <div {...props} />
  }

  return <Link to={to} {...props} />
}

export const pageQuery = graphql`
  {
    allMdx(
      sort: {
        order: [ASC, DESC, ASC, ASC, ASC],
        fields: [frontmatter___new, frontmatter___status, frontmatter___brand, frontmatter___collab, frontmatter___model]
      }
      filter: {
        fileAbsolutePath: { regex: "\\/products/" }
        frontmatter: { status: { ne: "inactive" } }
      }
    ) {
      edges {
        node {
          id
          frontmatter {
            brand
            collab
            model
            price
            size
            status
            new
          }
          fields {
            relativeSlug
            slug
          }
        }
      }
    }
    allFile(filter: { relativePath: { regex: "/main.png$/" } }) {
      nodes {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
        relativePath
      }
    }
    mainPlaceholder: file(
      sourceInstanceName: { eq: "images" },
      name: { eq: "main-placeholder" }
    ) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
